import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import { HeroFeature } from "components/Hero";
import { ConferencePresentedItem } from "components/List";
import { BlockWrapper } from "components/Block";

import { Heading } from "templates/home-page/Hero/styles";
import { QuoteText, QuoteAuthor } from "templates/home-page/styles";

export const TalksPageTemplate = ({ page, markdown }) => {
  return (
    <>
      <HeroFeature content={page.hero} />
      <BlockWrapper
        pt={["50px", "50px", "60px"]}
        pb={["40px", "40px", "50px"]}
        px={["0", "0", "0", "10px"]}
      >
        <QuoteText>{page.quote.text}</QuoteText>
        <QuoteAuthor>{page.quote.author}</QuoteAuthor>
      </BlockWrapper>

      <Heading> </Heading>
      <BlockWrapper
        shade="light"
        pt={["50px", "50px", "60px"]}
        pb={["40px", "40px", "50px"]}
        px={["0", "0", "0", "10px"]}
      >
        {page.conferences_presented.map((conference, index) => (
          <ConferencePresentedItem content={conference} />
        ))}
      </BlockWrapper>
    </>
  );
};

const TalksPage = ({ data }) => {
  const { frontmatter: page } = data.allMarkdownRemark.edges[0].node;
  return (
    <Layout page={page} shade="light" headerShade="light" footerShade="light">
      <TalksPageTemplate page={page} />
    </Layout>
  );
};

TalksPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default TalksPage;

export const TalksPageQuery = graphql`
  query TalksPage {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "talks-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            page {
              title
            }
            hero {
              heading
              subheading
            }
            quote {
              text
              author
            }
            conferences_presented {
              title
              location
              date
              path
              presentation_link
            }
          }
        }
      }
    }
  }
`;
