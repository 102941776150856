import styled from "styled-components";

import theme from "config/theme";

export const Background = styled.img`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 150%;
  max-width: 100vw;
  height: auto;
  min-height: 1000px;
  object-fit: cover;
  z-index: 1;
`;

export const QuoteText = styled.div`
  width: 100%;
  height: 70%;
  color: ${theme.color.gray3};
  font-size: ${theme.font.size.gamma};
  font-style: oblique;
  text-align: center;
`;

export const QuoteAuthor = styled.div`
  width: 100%;
  height: 25%;
  color: ${theme.color.gray3};
  font-size: ${theme.font.size.gamma};
  font-style: oblique;
  text-align: center;
`;
